<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: gateway.vue
Description: This component contains UI components of gateway tab of settings. It contains gateway settings data table
-->
<template>
  <el-row
    class="gateway-container"
    v-loading="loading"
    element-loading-background="white"
  >
    <Loader v-if="loading"></Loader>
    <el-row v-if="!loading || tableData.length > 0">
      <layout-toolbar justify="end" style="margin: 10px 10px">
        <er-input
          inputType="search"
          size="mini"
          v-model="search"
          v-show="!loading"
          suffix-icon="el-icon-search"
          :placeholder="$t('Comn_search')"
          @change="handelSearchChange"
        ></er-input>

        <er-button
          size="mini"
          btnType="save"
          :showLabel="true"
          :showIcon="true"
          v-show="!loading"
          :loading="buttonloading"
          :disabled="isReadOnly"
          @click="handleSaveDetails"
        ></er-button>

        <er-button
          size="mini"
          btnType="cancel"
          :showLabel="true"
          :disabled="isReadOnly"
          @click="handleCancel"
          v-show="!loading"
        ></er-button>
        <div @click="handleOpenDialog">
          <span class="material-icons-outlined action-icon"> queue </span>
        </div>
        <er-dropdown
          placement="bottom"
          @item-click="handleTableDataDownload"
          trigger="click"
          class="highlight"
          :listOfItems="['Comn_download_as_excel', 'Comn_download_as_pdf']"
        >
          <template class="el-dropdown-link download_button" slot="suffix">
            <span class="material-icons-outlined action-icon">
              file_download
            </span>
          </template>
          <template v-slot:item-title="{ item }">{{ $t(item.value) }}</template>
        </er-dropdown>
      </layout-toolbar>
      <el-row type="flex" justify="center" class="settings-gateway-table">
        <el-col :span="24" :md="20" :lg="15" :xl="10">
          <er-data-tables
            ref="gatewaySettingsTable"
            uniqueId="gateway-settings__table"
            :tableData="tableData"
            :key="$i18n.locale"
            :columns="tableColumns"
            :elTableProps="tableProps"
            :actionColumn="false"
            v-show="!loading"
            size="mini"
            :pagination-props="$constants.table['pagination-props']"
            :default-sort="{ prop: 'code', order: 'ascending' }"
            type="white-header-table"
            :searchOnTableData="search"
          >
            <template slot="empty">
              <span>
                <img class="no-data__img" src="@/assets/no_data.svg" />
              </span>
              <span class="no-data__text">
                <h4>{{ $t("Comn_no_data_connection") }}</h4>
              </span>
            </template>
            <template v-slot:code="{ row }">
              <er-highlight-search-component
                :highlightStrings="row.data.highlightStrings"
                :individualRowData="row.data"
                keyName="code"
                :withoutHighlightStrings="row.data.code"
              />
            </template>

            <template v-slot:alias_name="{ row }">
              <el-input
                size="mini"
                v-model="row.data.title"
                @change="handleGatewayChangeInTable($event, row.data)"
              ></el-input>
            </template>
            <template v-slot:location_id="{ row }">
              <er-select
                v-model="row.data.locations[0]"
                :value-key="row.data.location_id"
                size="mini"
                class="location-selector"
                @change="handleGatewayChangeForLocationInTable($event, row.data)"
              >
                <el-option
                  v-for="location in getLocationOfUser"
                  :key="location._id"
                  :value="location._id"
                  :label="location.name"
                >
                  {{ location.name }}
                </el-option>
              </er-select>
            </template>
            <template
              v-if="showAllowOtherLocationDisplay"
              v-slot:allow_other_location_device="{ row }"
            >
              <el-checkbox
                v-model="row.data.allow_all_devices"
                :disabled="shouldDisable(row.data)"
                @change="handleGatewayChangeInTable($event, row.data)"
              ></el-checkbox>
            </template>
            <template v-slot:manual_wifi_name="{ row }">
              <el-form ref="wifiNameForm" :model="wifiNameForm">
                <el-form-item>
                  <el-input
                    size="mini"
                    autocomplete="off"
                    v-model.trim="row.data.manual_wifi_name"
                    @keypress.native="checkWifiName($event)"
                    @change="handleGatewayChangeInTable($event, row.data)"
                  ></el-input>
                </el-form-item>
              </el-form>
            </template>
            <template v-slot:manual_wifi_password="{ row }">
              <el-form ref="wifiPasswordForm" :model="wifiPasswordForm">
                <el-form-item>
                  <el-input
                    size="mini"
                    type="password"
                    autocomplete="off"
                    show-password
                    v-model.trim="row.data.manual_wifi_password"
                    @change="handleGatewayChangeInTable($event, row.data)"
                  ></el-input>
                </el-form-item>
              </el-form>
            </template>
          </er-data-tables>
        </el-col>
      </el-row>
    </el-row>
    <er-no-ponds :tabData="tabData" :disabled="isReadOnly" v-else />
    <component
      :is="`gatewayActionDialog`"
      :showDialog="dialogVisible"
      @close_dialog="closeDialog"
    ></component>
  </el-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import notificationMixin from "@/mixins/notificationMixin";
import filtersMixin from "@/mixins/filtersMixin";
import Loader from "@/components/base/Loader";
import exportFileDownload from "@/mixins/exportFileDownload";
import gatewayActionDialog from "./gatewayActionDialog.vue";
export default {
  mixins: [
    errorHandlerMixin,
    notificationMixin,
    filtersMixin,
    exportFileDownload
  ],
  components: {
    Loader,
    gatewayActionDialog
  },
  props: {
    tabData: {
      default: {}
    }
  },
  data: function() {
    return {
      isTableEdited: false,
      showAllowOtherLocationDisplay: false,
      loading: false,
      search: "",
      buttonloading: false,
      tableProps: {
        height: 'calc(100vh - var(--table-gutter))'
      },
      optionValues: {},
      wifiNameForm: {
        wifiName: ''
      },
      wifiPasswordForm: {
        wifiPassword: ''
      },
      dialogVisible: false
    };
  },
  created() {
    const features = this.getUserProfile.features;
    if (Object.keys(features).length > 0) {
      const { gateway_location_authentication } = features;
      this.showAllowOtherLocationDisplay = gateway_location_authentication;
    }
    if (!this.showAllowOtherLocationDisplay) {
      this.downloadColumns.map((item, i) => {
        console.log(item, i);
        if (item.name === "allow_all_devices") {
          this.downloadColumns.splice(i, 1);
        }
      });
      delete this.tableColumns.allow_other_location_device;
    }
  },
  computed: {
    ...mapGetters("gateway", {
      getGatewayData: "getGatewayData",
      getGatewayDataLoading: "getGatewayDataLoading"
    }),
    ...mapGetters("user", {
      getCurrUserLocation: "getCurrUserLocation",
      getUserLocations: "getUserLocations",
      isReadOnly: "isReadOnly",
      getUserLocationsObj: "getUserLocationsObj",
      getUserProfile: "getUserProfile"
    }),
    ...mapGetters("pond", {
      Ponds: "getPonds"
    }),
    tableData() {
      return this.search !== '' ? this.$lodash.cloneDeep(this.getGatewayData).filter((x) =>
            x.code.toLowerCase().includes(this.search.toLowerCase())
          ) : this.$lodash.cloneDeep(this.getGatewayData)
    },
    getLocationOfUser() {
      return this.getUserLocations.map((item) => {
        return {
          ...item,
          name: this.ftm__capitalize(item.name)
        };
      });
    },
    getObjGatewayData: function() {
      return this.getGatewayData.reduce((acc, x) => {
        acc[x._id] = x;
        return acc;
      }, {});
    },
    tableColumns() {
      return {
        code: {
          prop: "code",
          label: this.$t("Gateway_gateway_id"),
          // width: 200,
          sortable: "true",
          sortMethod: this.$commonUtils.naturalSort("code"),
          searchProperty: "code",
          enableSearch: true
        },
        alias_name: {
          prop: "title",
          label: this.$t("Comn_alias_name"),
          searchProperty: "title",
          enableSearch: true

          // width: 150
        },
        location_id: {
          prop: "locations",
          label: this.ftm__capitalize(this.$tc("Comn_location.case.lower", 2))
        },
        allow_other_location_device: {
          prop: 'allow_all_devices',
          label: this.$t('allow_other_location_device')
        },
        manual_wifi_name: {
          prop: 'manual_wifi_name',
          label: this.$t('manual_wifi_name_label')
        },
        manual_wifi_password: {
          prop: 'manual_wifi_password',
          label: this.$t('manual_wifi_password_label')
        }
      };
    },
    downloadColumns() {
      return [
        {
          header: this.tableColumns.code.label,
          dataKey: "code",
          emptyValue: "--",
          name: "code",
          skipColumn: false,
          formatters: [],
          xcelcolumnWidth: 15
        },
        {
          header: this.tableColumns.alias_name.label,
          dataKey: "title",
          emptyValue: "--",
          name: "title",
          skipColumn: false,
          formatters: [],
          xcelcolumnWidth: 20
        },
        {
          header: this.tableColumns.location_id.label,
          dataKey: "locations",
          emptyValue: "--",
          name: "locations",
          skipColumn: false,
          formatters: [],
          xcelcolumnWidth: 20
        },
        {
          header: this.tableColumns.allow_other_location_device.label,
          dataKey: "allow_all_devices",
          emptyValue: "--",
          name: "allow_all_devices",
          skipColumn: false,
          formatters: [],
          xcelcolumnWidth: 20
        },
        {
          header: this.tableColumns.manual_wifi_name.label,
          dataKey: 'manual_wifi_name',
          emptyValue: '--',
          name: 'manual_wifi_name',
          skipColumn: false,
          formatters: [],
          xcelcolumnWidth: 20
        },
        {
          header: this.tableColumns.manual_wifi_password.label,
          dataKey: 'manual_wifi_password',
          emptyValue: '--',
          name: 'manual_wifi_password',
          skipColumn: false,
          formatters: [],
          xcelcolumnWidth: 20
        }
      ];
    }
  },
  async mounted() {
    this.loading = true;
    this.optionValues = this.getLocationOfUser.reduce((acc, curt) => {
        acc[curt._id] = curt;
        return acc;
      }, {})
    try {
      await this.fetchAllPonds({
        location_id: this.getCurrUserLocation._id,
        get_all: true,
        status: ["ACTIVE", "INACTIVE"]
      });
      await this.callStoreToFetchGatewayDetails();
    } catch (err) {
      this.ehm__errorMessages(err, true);
    } finally {
      this.$nextTick(() => {
        if (this.$refs.gatewaySettingsTable) {
          this.$refs.gatewaySettingsTable.$refs.dataTables.$refs.elTable.doLayout();
        }
      });
      this.loading = false;
    }
  },
  methods: {
    ...mapActions("gateway", {
      fetchGatewayData: "fetchGatewayData",
      saveGatewayDetails: "saveGatewayDetails"
    }),
    ...mapActions("pond", {
      fetchAllPonds: "fetchAllPonds"
    }),
    ...mapActions("user", {
      mixPanelEventGenerator: "mixPanelEventGenerator"
    }),
    handelSearchChange() {
      if (this.search !== '') {
        this.mixPanelEventGenerator({ eventName: "Settings - Gateway - Search" });
      }
    },
    shouldDisable(data) {
      return data.locations.length === 0;
    },
    checkWifiName(e) {
      const char = String.fromCharCode(e.keyCode);
      if (/^[A-Za-z0-9]+$/.test(char)) return true;
      else e.preventDefault();
    },
    async initComponent() {
      this.callStoreToFetchGatewayDetails();
    },
    handleOpenDialog() {
      try {
        this.$gblUAMCanUserEdit(this.tabData);
        this.dialogVisible = true;
      } catch (error) {
        this.ehm__errorMessages(error, true);
      }
    },
    async closeDialog(event, input) {
      this.dialogVisible = false;
      if (input === 'retrieveData') {
        await this.initComponent();
      }
    },
    async callStoreToFetchGatewayDetails() {
      this.loading = true;
      try {
        await this.fetchGatewayData();
        this.tableData = this.tableData
          .map((x) => {
            x.title = x.title || x.code;
            x.locations = x.locations.filter(val => this.optionValues[val] !== undefined)
            return x;
          });
        if (this.tableData.length > 0) {
          this.tableData.map((item) => {
            if (!item.hasOwnProperty('manual_wifi_name')) {
              item.manual_wifi_name = '';
            }
            if (!item.hasOwnProperty('manual_wifi_password')) {
              item.manual_wifi_password = '';
            }
          });
        }
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        this.loading = false;
      }
    },
    handleCancel() {
      this.tableData = this.$lodash.cloneDeep(this.getGatewayData);
    },
    getLocationName(location_id) {
      return this.getUserLocationsObj[location_id]?.name || "";
    },
    handleTableDataDownload(downloadtype) {
      if (downloadtype === "Comn_download_as_pdf") {
        this.pdfdownload();
      } else {
        this.xceldownload();
      }
      this.mixPanelEventGenerator({ eventName: "Settings - Gateway - Download" });
    },
    downloadData() {
      const data = this.$refs.gatewaySettingsTable.$refs.dataTables.sortedData.reduce(
        (
          acc,
          {
            title,
            locations,
            code,
            allow_all_devices,
            manual_wifi_name,
            manual_wifi_password
          }
        ) => {
          const data = locations.map((x) => {
            return this.showAllowOtherLocationDisplay === true
              ? {
                  code: code,
                  title: title,
                  locations: this.getLocationName(x),
                  allow_all_devices: allow_all_devices,
                  manual_wifi_name,
                  manual_wifi_password
                }
              : {
                  code: code,
                  title: title,
                  locations: this.getLocationName(x),
                  manual_wifi_name,
                  manual_wifi_password
                };
          });
          acc.push(...data);
          return acc;
        },
        []
      );
      return data;
    },
    xceldownload() {
      this.exp__Download(this.downloadData(), this.downloadColumns, "xcel");
    },
    pdfdownload() {
      this.exp__Download(this.downloadData(), this.downloadColumns, "pdf");
    },

    async handleSaveDetails() {
      this.buttonloading = true;
      const errors = [];

      try {
        this.$gblUAMCanUserEdit(this.tabData);
        const payload = this.tableData.map((x, index) => {
          if (!x.title) {
            errors.push({
              message: `${this.$t("allias_name_must_be_filled")} ${this.$t(
                "Comn_in"
              )} ${this.$t("Comn_row")} ${index + 1}`
            });
          }
          const prepPayload =
            this.showAllowOtherLocationDisplay === true
              ? {
                  gateway_id: x._id,
                  title: x.title.trim(),
                  location_ids: x.locations,
                  allow_all_devices: x.allow_all_devices,
                  manual_wifi_name: x.manual_wifi_name,
                  manual_wifi_password: x.manual_wifi_password
                }
              : {
                  gateway_id: x._id,
                  title: x.title.trim(),
                  location_ids: x.locations,
                  manual_wifi_name: x.manual_wifi_name,
                  manual_wifi_password: x.manual_wifi_password
                };
          if (
            prepPayload.manual_wifi_name === null ||
            prepPayload.manual_wifi_name === ''
          ) {
            delete prepPayload.manual_wifi_name;
          }
          if (
            prepPayload.manual_wifi_password === null ||
            prepPayload.manual_wifi_password === ''
          ) {
            delete prepPayload.manual_wifi_password;
          }
          return prepPayload;
        });
        if (!this.isTableEdited) {
          throw {
            type: "FAIL_TO_SAVE",
            errors: [
              {
                message: this.$t("Comn_no_changes_found", {
                  item: this.$t("Comn_gateway")
                })
              }
            ]
          };
        }
        if (errors.length > 0) {
          throw {
            type: "FAIL_TO_SAVE",
            errors: errors
          };
        }
        await this.saveGatewayDetails(payload);
        this.mixPanelEventGenerator({ eventName: "Settings - Gateway - Save" });
        this.nofm__notifySuccess({
          msgLangkey: {
            title: "Comn_update_success_msg",
            count: 2,
            child: { item: this.$t("Comn_gateway") }
          }
        });
        await this.callStoreToFetchGatewayDetails();
      } catch (err) {
        this.ehm__errorMessages(err);
      } finally {
        this.buttonloading = false;
      }
    },
    handleGatewayChangeInTable(event, gw) {
      this.isTableEdited = true;
      if (this.search !== '') {
        const tableDataCopy = this.tableData.map((cur) => {
          cur.title = cur._id === gw._id ? gw.title : cur.title;
          return cur;
        })
        this.tableData = tableDataCopy;
      }
    },
     handleGatewayChangeForLocationInTable(event, gw) {
      this.isTableEdited = true;
      if (this.search !== '') {
        const tableDataCopy = this.tableData.map((cur) => {
          cur.locations = cur._id === gw._id ? gw.locations : cur.locations;
          return cur;
        })
        this.tableData = tableDataCopy;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.gateway-container {
  .el-form {
    .el-form-item {
      margin-bottom: 0 !important;
    }
  }
  .action-icon {
    cursor: pointer;
    color: black;
    opacity: 0.3;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    @include icon-size(icon-font);
    &:hover {
      opacity: 1;
      cursor: pointer;
    }
  }
  // .el-form-item {
  //   margin-bottom: 0 !important;
  // }
  @include responsiveProperty(--table-gutter, 197px, 210px, 220px);
  .post-header {
    padding-bottom: 10px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
  .settings-gateway-table {
    // padding-top: 12px;

    padding-bottom: 12px;
    &.el-table .el-input__inner {
      width: 130px;
    }
    .el-table_1_column_4,
    .el-table_2_column_8,
    .is-center {
      .cell {
        text-align: center;
        margin-left: 22px;
      }
    }
  }
  .er-select.location-selector {
    @include responsiveProperty(width, 100%, 100%, 100%);
  }
  .er-select .el-tag__close {
    margin-top: 0px;
  }
}
</style>
